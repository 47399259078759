<template>
  <div class="promo-page">

    <div id="sidebar-wrapper" class="active" v-if="isMenuShow">
      <div class="container">
        <a href="#" @click.prevent="updateMenuShow()" class="close-icon"><span class="fa fa-close"></span></a>
        <div class="caption">Main</div>
        <div class="sidebar-nav">
          <div class="item">
            <a href="#" @click.prevent="doScroll('work')">
              How does it work
            </a>
          </div>
          <div class="item" @click.prevent="doScroll('partner')">
            <a href="#">
              Boogy partners
            </a>
          </div>
          <div class="item" @click.prevent="doScroll('review')">
            <a href="#">
              Reviews
            </a>
          </div>
          <div class="item" @click.prevent="doScroll('faq')">
            <a href="#">
              FAQ
            </a>
          </div>
          <div class="item" @click.prevent="doScroll('partner-form')">
            <a href="#">
              For Partners
            </a>
          </div>
        </div>
        <div class="buttons">
          <router-link to="/user/register" class="btn btn-form">
            Begin
          </router-link>
        </div>
        <div class="social">
          <a :href="this.getFacebookUrl()" class="link" target="_blank">
            <span class="icon-round">
              <span class="fa fa-facebook"></span>
            </span>
          </a>
          <a :href="this.getWatsappUrl()" class="link" target="_blank">
            <span class="fa fa-whatsapp"></span>
          </a>
          <a :href="this.getInstagramUrl()" class="link" target="_blank">
            <span class="fa fa-instagram"></span>
          </a>
        </div>
      </div>
    </div>
    <div class="top-menu">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="logo">
              <img src="~@/assets/img/promo/logo.png"/>
            </div>
          </div>
          <div class="col-6">
            <div class="bars">
              <a href="#" @click.prevent="updateMenuShow()"><span class="fa fa-bars"></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-block">
      <div>
        <svg width="82" height="123" viewBox="0 0 82 123" fill="none" xmlns="http://www.w3.org/2000/svg" class="obj-1">
          <circle cx="61.5" cy="61.5" r="61.5" fill="white"/>
          <circle cx="61" cy="61" r="37" fill="#FFFA83"/>
        </svg>
        <svg width="104" height="164" viewBox="0 0 104 164" fill="none" xmlns="http://www.w3.org/2000/svg"
             class="obj-2">
          <circle cx="21.8983" cy="82.3984" r="81.5203" fill="#3F414E" fill-opacity="0.3"/>
        </svg>
        <svg width="124" height="123" viewBox="0 0 124 123" fill="none" xmlns="http://www.w3.org/2000/svg"
             class="obj-3">
          <circle opacity="0.3" cx="62" cy="61.5" r="61.5" fill="#00F0FF" fill-opacity="0.3"/>
          <circle cx="61.5" cy="61" r="37" fill="white"/>
        </svg>
      </div>
      <div class="container">
        <div class="title">
          What <br/>
          Is Boogy?
        </div>
        <div class="description">
          Boogy is the first service in Israel that allows you to do fitness in any city of the country, where and when
          it suits you!
        </div>
        <div class="description-bottom">
          But the most interesting thing is that you pay only for the time that you spent training!
        </div>
        <div class="buttons">
          <router-link to="/user/register" class="btn btn-form">
            Begin
          </router-link>
        </div>
      </div>
      <div class="video-block">
        <span class="video-inner">
          <img src="~@/assets/img/promo/section-img-2.jpg" class="video-img"/>
          <a href="#" class="video-play" @click.prevent="showVideo()"></a>
          <span class="info">
            The short video <br/>
            to know how boogy works
          </span>
        </span>
      </div>
    </div>
    <div id="block-work"></div>
    <div class="section-block-2">
      <div class="container top-block">
        <div class="title">
          How does <br/>
          it work?
        </div>
        <div class="description">
          <strong>You do not need to download</strong>
          anything, just visit boogy.fit and follow few simple steps:
        </div>
      </div>
      <div class="promo-slider-block">
        <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="50"
            :pagination="true"
        >
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="image">
                <img src="~@/assets/img/promo/slide-1.png"/>
              </div>
              <div class="description">
                <span>Register</span> and verify your email
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-2">
              <div class="image">
                <img src="~@/assets/img/promo/slide-2.png"/>
              </div>
              <div class="description">
                <span>Find gyms on the map</span> that suit <br/>
                your budget and lifestyle
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-3">
              <div class="image">
                <img src="~@/assets/img/promo/slide-3.png"/>
              </div>
              <div class="description">
                <span>Scan the QR code on the reception</span> <br/>
                using boogy.fit. <br/>
                Workout starts!
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-4">
              <div class="image">
                <img src="~@/assets/img/promo/slide-4.png"/>
              </div>
              <div class="description">
                At the end of your workout, do not <br/>
                forget to scan again the QR code to <br/>
                <span>pay only for the minutes you spent in the gym</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="section-block-3">
      <div class="top-block">
        <div class="image">
          <img src="~@/assets/img/promo/section-3-img-1-2.jpg"/>
        </div>
        <div class="text">
          <p>
            Every day, only you choose in which city of the country, in which fitness center, and what you do with
            <strong>Boogy!</strong>
          </p>
          <div class="buttons">
            <router-link to="/user/register" class="btn btn-form">
              Begin
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div id="block-partner"></div>
    <div class="section-block-4">
      <div class="container">
        <div class="top-block">
          <div class="title">Boogy Members</div>
          <div class="image" v-if="windowWidth > 550">
            <img src="~@/assets/img/promo/section-3-logo-1.png"/>
            <img src="~@/assets/img/promo/section-3-logo-2.png"/>
            <img src="~@/assets/img/promo/section-3-logo-3.png"/>
          </div>
          <div class="image" v-if="windowWidth <= 550">
            <swiper
                ref="swiper-partners"
                :modules="modules"
                :style="{
                '--swiper-navigation-color': '#07f0ff'
              }"
                :slides-per-view="1"
                :space-between="0"
                :loop="true"
                :breakpoints="{
                '350': {
                  slidesPerView: 2
                }
              }"
            >
              <swiper-slide>
                <img src="~@/assets/img/promo/section-3-logo-1.png"/>
              </swiper-slide>
              <swiper-slide>
                <img src="~@/assets/img/promo/section-3-logo-2.png"/>
              </swiper-slide>
              <swiper-slide>
                <img src="~@/assets/img/promo/section-3-logo-3.png"/>
              </swiper-slide>
            </swiper>
            <button @click="partnersSwPrev" class="partner-swiper-btn swiper-btn-left">
              <img src="~@/assets/img/promo/swiper-arrow-back.png"/>
            </button>
            <button @click="partnersSwNext" class="partner-swiper-btn swiper-btn-right">
              <img src="~@/assets/img/promo/swiper-arrow-forward.png"/>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="block-review"></div>
    <div class="section-review">
      <div class="container">
        <div class="title">Reviews</div>
        <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="50"
            :pagination="true"
        >
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="name">Anton 30 y.o.</div>
              <div class="text">
                I absolutely love the convenience of Boogy! I can just show up at any gym and start working out
                without
                having to worry about pre-booking or any long-term commitment. It's also really affordable and easy
                to
                use. I highly recommend it to anyone who wants to stay fit and flexible!
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="name">Muhammad 26 y.o.</div>
              <div class="text">
                Boogy is a game-changer for busy people like me. I can easily squeeze in a workout at any time of
                day
                without having to plan ahead or worry about scheduling conflicts. The app is user-friendly and I
                love
                that I can track my progress and see my progress over time. Highly recommended!
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="name">Joe 24 y.o.</div>
              <div class="text">
                As a student, I don't have a lot of time or money to invest in a gym membership, but Boogy has made
                it
                easy for me to get the fitness I need. I can drop into any gym in Israel and start working out right
                away. The pricing is fair and I love that I can choose to pay by the minute. Boogy has made fitness
                accessible and convenient for me and I couldn't be happier!
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="name">Sarah, 34 y.o.</div>
              <div class="text">
                I have been using Boogy for a few months now and I have to say it is an amazing service. I no longer
                have to worry about carrying cash or signing up for long term gym memberships. The process is so
                simple
                and convenient, I can just show up at any gym and start my workout without any hassle.
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="name">David, 41</div>
              <div class="text">
                As a busy professional, I don't have a lot of free time to go to the gym. With Boogy, I can work out
                whenever I have a spare moment without worrying about wasting money on a full membership. The app is
                easy to use and the payments are quick and hassle-free.
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="name">Sara 30 y.o.</div>
              <div class="text">
                As a busy mom, I don't always have the time to plan out my workout schedule. Boogy allows me to fit
                in a
                quick session whenever I have a spare moment, without having to worry about paying for an entire
                month.
                So convenient!
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="name">David 18 y.o.</div>
              <div class="text">
                I love the flexibility Boogy provides for my workout routine. I can choose to go to a different gym
                every day if I want, without having to worry about extra costs. The app is super user-friendly and
                makes
                working out so much easier.
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="name">Nikita 31 y.o.</div>
              <div class="text">
                I was hesitant to try Boogy at first because I thought I would need to purchase a gym membership to
                use
                it. But I was pleasantly surprised to find out that I can just pay for each session on the spot!
                This
                has made working out so much more accessible for me and I'm so grateful for this service.
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="name">Marc 24 y.o.</div>
              <div class="text">
                One of the things I love most about Boogy is that I don't have to commit to a monthly gym
                membership. I
                can just pay for each session as I go, which is perfect for my unpredictable schedule. The process
                is
                super simple and I love that I have the freedom to workout at any gym in Israel without any
                long-term
                obligations.
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="name">Alexander 27 y.o.</div>
              <div class="text">
                As someone who travels frequently, I was thrilled to discover Boogy. I can now workout at any gym in
                Israel without having to worry about purchasing a membership or committing to a certain number of
                sessions. The pay-per-minute feature is incredibly convenient and I appreciate not having to worry
                about
                any long-term commitments. Boogy has made working out so much easier for me.
              </div>
            </div>
          </swiper-slide>
        </swiper>

      </div>
    </div>

    <div id="block-faq"></div>
    <div class="section-faq">
      <div class="container">
        <div class="title">FAQ</div>
        <div class="faq-item expand">
          <a href="#" class="caption" @click.prevent="toggleFaq($event.currentTarget)">
            <span class="fa fa-chevron-down expand-hide"></span>
            <span class="fa fa-chevron-up expand-show"></span>
            When the training is considered to start?
          </a>
          <div class="text">
            The timer for the session runs when you enter the gym, then scan the QR code at the reception desk. Why
            is
            it done this way? Because when you enter the gym, you leave your belongings in the locker room or
            particular
            storage areas if they are available. The locker room, restrooms, etc. are also part of the gym, so they
            are
            included in the cost as well. Of course, you can come to the gym in workout clothes and you don't have
            to
            waste time changing. Of course, everything is individual, but you determine the duration of the time
            spent
            in the locker room or training.
          </div>
        </div>
        <div class="faq-item expand">
          <a href="#" class="caption" @click.prevent="toggleFaq($event.currentTarget)">
            <span class="fa fa-chevron-down expand-hide"></span>
            <span class="fa fa-chevron-up expand-show"></span>
            Subscription or Boogy?
          </a>
          <div class="text">
            An average person who buys a gym membership typically exercises once a week. According to a study, 90%
            of
            gym members workout 3-4 times a month, unless they are professional athletes who spend more time in the
            gym.
            Unfortunately, less than 50% of people renew their membership after it expires.<br/>
            Boogy is perfect for:<br/>
            Those who frequently change locations due to their lifestyle<br/>
            Those who have an irregular exercise schedule<br/>
            Those who are always traveling<br/>
            Those who are looking for new and exciting activities<br/>
            Boogy is like car sharing or scooter sharing, but instead of transportation, you pay only for the time
            spent
            working out at the gym. Perfect for you!
          </div>
        </div>

        <div class="faq-item expand">
          <a href="#" class="caption" @click.prevent="toggleFaq($event.currentTarget)">
            <span class="fa fa-chevron-down expand-hide"></span>
            <span class="fa fa-chevron-up expand-show"></span>
            Do I have to pay upfront for workouts or sign up at a gym? I don't want to do that, I just want to come
            and
            work out!
          </a>
          <div class="text">
            NO!<br/>
            With Boogy, there is no need to pay upfront<br/>
            With Boogy, there is no need to sign up for workouts<br/>
            With Boogy, there are no subscriptions or memberships to buy<br/>
            With Boogy, you don't have to worry about pandemic lockdowns, because you only pay after you finish your
            training!<br/>
            With Boogy, you can even work out without talking to anyone, find a gym connected to the system, work
            out
            there, and pay only after you finish your workout.
          </div>
        </div>

        <div class="faq-item expand">
          <a href="#" class="caption" @click.prevent="toggleFaq($event.currentTarget)">
            <span class="fa fa-chevron-down expand-hide"></span>
            <span class="fa fa-chevron-up expand-show"></span>
            Who is suitable for Boogy?
          </a>
          <div class="text">
            Are you a tourist, immigrant, a busy person, or on a business trip facing difficulties in purchasing a
            gym
            membership? Some gyms even impose cancellation fees, making the process even harder. Boogy has the
            perfect
            solution for you!
            <br/>
            Work out for a cost that's as low as just a cup of coffee. Balance your work and home life and choose a
            gym
            that's conveniently located. Work out with friends, stack your sessions, and improve with every minute
            of
            exercise! Boogy makes it simple and affordable to reach your fitness goals.
          </div>
        </div>
        <div class="faq-item expand">
          <a href="#" class="caption" @click.prevent="toggleFaq($event.currentTarget)">
            <span class="fa fa-chevron-down expand-hide"></span>
            <span class="fa fa-chevron-up expand-show"></span>
            Is there a gym near my house where I can train with Boogy?
          </a>
          <div class="text">
            You can easily recommend a gym to join Boogy by filling out the feedback form on the bottom of the page,
            or
            you can email <a href="mailto:support@boogy.fit">support@boogy.fit</a> and our team will contact you to
            connect the gym. The best part is that every client who helps connect a gym will receive a bonus!
          </div>
        </div>
        <div class="faq-item expand">
          <a href="#" class="caption" @click.prevent="toggleFaq($event.currentTarget)">
            <span class="fa fa-chevron-down expand-hide"></span>
            <span class="fa fa-chevron-up expand-show"></span>
            Why is there a hold on my card at the start of the workout?
          </a>
          <div class="text">
            At Boogy, we prioritize our customer's peace of mind and to ensure you don't have to worry about running
            out
            of funds during your workout, we pre-authorize your card for an amount equal to 4 hours of gym time. 4
            hours
            is the maximum duration of your workout after which the system will automatically end the session. These
            funds are temporarily blocked and will be released after the workout is completed. This way, you don't
            have
            to think about having enough funds during your workout, we've got you covered
          </div>
        </div>
        <div class="faq-item expand">
          <a href="#" class="caption" @click.prevent="toggleFaq($event.currentTarget)">
            <span class="fa fa-chevron-down expand-hide"></span>
            <span class="fa fa-chevron-up expand-show"></span>
            How do I finish a workout if there are problems with my phone?
          </a>
          <div class="text">
            If your phone died, lost signal, or got broken during your workout, don't worry! Simply go to the
            reception
            desk and show your ID, and the gym manager will finish your workout for you, so you will not waste any
            more
            time in the gym.
          </div>
        </div>
      </div>
    </div>

    <div id="block-partner-form"></div>
    <div class="section-bottom">
      <div class="title">For Partners</div>

      <form class="h-form" @submit.prevent="doPartnerBidAdd">

        <div class="form-group mb-3">
          <input
              v-model="name"
              :class="{'is-invalid': errors?.name}"
              type="text"
              class="form-control rounded-4"
              id="name-input"
              autocomplete="false"
              placeholder="Full name"/>
          <div class="invalid-feedback">{{ errors?.name }}</div>
        </div>

        <div class="form-group mb-3">
          <input
              v-model="email"
              :class="{'is-invalid': errors?.email}"
              type="text"
              class="form-control rounded-4"
              id="email-input"
              autocomplete="false"
              placeholder="Email"/>
          <div class="invalid-feedback">{{ errors?.email }}</div>
        </div>

        <div class="form-group mb-3">
          <input
              v-model="phone"
              :class="{'is-invalid': errors?.phone}"
              type="text"
              class="form-control rounded-4"
              id="phone-input"
              autocomplete="false"
              placeholder="Phone number"/>
          <div class="invalid-feedback">{{ errors?.phone }}</div>
        </div>

        <div class="form-group mb-3">
          <input
              v-model="placeTitle"
              :class="{'is-invalid': errors?.placeTitle}"
              type="text"
              class="form-control rounded-4"
              id="placeTitle-input"
              autocomplete="false"
              placeholder="Name of fitness club"/>
          <div class="invalid-feedback">{{ errors?.placeTitle }}</div>
        </div>

        <div class="form-group mb-3">
          <input
              v-model="city"
              :class="{'is-invalid': errors?.city}"
              type="text"
              class="form-control rounded-4"
              id="city-input"
              autocomplete="false"
              placeholder="City"/>
          <div class="invalid-feedback">{{ errors?.city }}</div>
        </div>

        <div class="form-group mb-3">
          <input
              v-model="site"
              :class="{'is-invalid': errors?.site}"
              type="text"
              class="form-control rounded-4"
              id="site-input"
              autocomplete="false"
              placeholder="Link to the web-site"/>
          <div class="invalid-feedback">{{ errors?.site }}</div>
        </div>

        <div class="h-subtext">
          By clicking, you agree to
          <router-link :to="{path: '/page/agreement', query: {back: 'promo'}}">the terms of use</router-link>
        </div>

        <button class="btn btn-form mt-2" type="submit">
          Let’s get started
        </button>

      </form>

      <div class="contacts">
        <div class="social">
          <a :href="this.getInstagramUrl()" class="link" target="_blank">
            <span class="fa fa-instagram"></span>
          </a>
          <a :href="this.getWatsappUrl()" class="link" target="_blank">
            <span class="fa fa-whatsapp"></span>
          </a>
          <a :href="this.getFacebookUrl()" class="link" target="_blank">
          <span class="icon-round">
            <span class="fa fa-facebook"></span>
          </span>
          </a>
        </div>
        <div class="info">
          <span class="fa fa-envelope-o"></span> <a href="mailto:support@boogy.fit"
                                                    target="_blank">support@boogy.fit</a> <br/>
          <span class="fa fa-file-o"></span>
          <router-link :to="{path: '/page/agreement', query: {back: 'promo'}}">User agreement</router-link>
          <br/>
          <span class="fa fa-file-o"></span>
          <router-link :to="{path: '/page/rules', query: {back: 'promo'}}">Visit rules</router-link>
          <br/>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";
import {Navigation, Pagination} from 'swiper';
import 'swiper/swiper-bundle.css';
import {Fancybox} from '@fancyapps/ui/src/Fancybox/Fancybox.js';
import '@fancyapps/ui/dist/fancybox.css';

export default {

  components: {Swiper, SwiperSlide},

  setup() {

    return {
      modules: [Pagination, Navigation]
    };
  },

  data: () => ({
    name: null,
    email: null,
    phone: null,
    placeTitle: null,
    city: null,
    site: null,

    isMenuShow: false,

    windowWidth: 0
  }),

  async mounted() {

    if (await this.isPartner())
      return await this.redirect('/partner');

    if (await this.isUser())
      return await this.redirect('/place/map');

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },

  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    partnersSwNext() {
      this.$refs["swiper-partners"].$el.swiper.slideNext();
    },

    partnersSwPrev() {
      this.$refs["swiper-partners"].$el.swiper.slidePrev();
    },

    updateMenuShow() {
      this.isMenuShow = !this.isMenuShow;
    },

    doScroll(name) {
      this.updateMenuShow();
      document.getElementById("block-" + name).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    },

    toggleFaq(item) {
      let block = item.closest('.faq-item');
      if (!block)
        return;

      block.classList.toggle('expand');
    },

    async doPartnerBidAdd() {

      let apiHolder = await this.sendApiPost('api/partner/bid/add', {
        name: this.name,
        email: this.email,
        phone: this.phone,
        placeTitle: this.placeTitle,
        city: this.city,
        site: this.site
      });

      if (!apiHolder.isSuccess())
        return;

      this.clearErrors();
      this.clearFields();

      this.showModal('Form has been successfully submitted');
    },

    clearFields() {
      this.name = this.email = this.phone = this.placeTitle = this.city = this.site = null;
    },

    showVideo() {
      Fancybox.show([{
        src: 'https://www.youtube.com/watch?v=cvBfq-EUkaQ&cc_load_policy=1'
      }], {});
    }
  }

}
</script>

<style scoped>

.container {
  position: relative;
}

#sidebar-wrapper {
  position: fixed;
  z-index: 1000;
  min-height: 100vh;
  width: 100%;
  background: #FFF;
  top: 0;
  left: 0;
}

#sidebar-wrapper .close-icon {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 20px;
  color: #000 !important;
}

#sidebar-wrapper .caption {
  text-align: center;
  padding-top: 100px;
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 20px;
  padding-left: 20px;
}

#sidebar-wrapper .sidebar-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

#sidebar-wrapper .sidebar-nav .item {
  line-height: 50px;
  text-indent: 20px;
  text-align: center;
  font-size: 24px;
}

#sidebar-wrapper .sidebar-nav .item a {
  color: #343434 !important;
  display: block;
  text-decoration: none;
}

#sidebar-wrapper .buttons {
  padding: 45px 10px 20px 15px;
  text-align: center;
}

#sidebar-wrapper .buttons .btn-form {
  max-width: 180px;
  padding: 15px 30px !important;
}

#sidebar-wrapper .social {
  text-align: center;
  padding-left: 15px;
}

#sidebar-wrapper .social .fa {
  width: 20px;
  color: #FFF;
  font-size: 28px;
  line-height: 20px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 20px;
}

#sidebar-wrapper .social .fa-whatsapp {
  color: #00D916;
}

#sidebar-wrapper .social .fa-instagram {
  color: #f65db1;
}

#sidebar-wrapper .social .icon-round {
  border-radius: 50%;
  background: #039BE5;
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 20px;
  text-align: center;
  margin-right: 20px;
}

#sidebar-wrapper .social .icon-round .fa {
  top: 3px;
  position: relative;
}

.top-menu {
  background: #f3f3f3;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.top-menu .logo img {
  max-width: 120px;
}

.top-menu .bars {
  position: absolute;
  top: 5px;
  right: 25px;
}

.top-menu .bars a {
  font-size: 20px;
  color: #000 !important;
}

.obj-1 {
  position: absolute;
  top: 34%;
  right: 0;
  opacity: 0.5;
}

.obj-2 {
  position: absolute;
  top: 24%;
  left: 0;
  opacity: 0.6;
}

.obj-3 {
  position: absolute;
  top: 7%;
  left: 31%;
  opacity: 0.6;
}

.section-block {
  background: url("~@/assets/img/promo/bg-top.png") no-repeat top center;
  background-size: cover;
  position: relative;
}

.section-block .title {
  font-size: 52px;
  font-weight: bold;
  line-height: 52px;
  color: #343434;
  padding-top: 180px;
}

.section-block .description {
  color: #000;
  font-size: 20px;
  padding-top: 35px;
  font-weight: bold;
}

.section-block .description-bottom {
  color: #000;
  font-size: 20px;
  padding-top: 20px;
}

.section-block .buttons {
  padding-top: 30px;
}

.section-block .buttons .btn-form {
  max-width: 250px;
  padding: 15px 20px !important;
}

.section-block .video-block {
  text-align: center;
  padding: 50px 15px 0 15px;
}

.section-block .video-block .video-img {
  border-radius: 20px;
  max-width: 100%;
}

.section-block .video-inner {
  position: relative;
  display: inline-block;
}

.section-block .video-inner .video-play {
  background: url("~@/assets/img/promo/video-play.svg") no-repeat top center;
  background-size: contain;
  display: block;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
}

.section-block .video-inner .info {
  display: block;
  position: absolute;
  left: 0;
  top: 63%;
  width: 100%;
  color: #FFF;
  text-align: center;
}

.section-block-2 {
  background: #f3f3f3;
  padding-top: 80px;
}

.section-block-2 .top-block .title {
  font-size: 52px;
  font-weight: bold;
  line-height: 52px;
  color: #343434;
}

.section-block-2 .top-block .description {
  color: #000;
  font-size: 20px;
  padding-top: 35px;
}

.promo-slider-block {
  margin: 0 auto 0 auto;
  border-radius: 10px;
  padding-top: 40px;
}

.promo-slider-block .inner-slide {
  padding: 30px 15px 30px 15px;
  border-radius: 10px;
  text-align: center;
  min-height: 700px;
}

.promo-slider-block .inner-slide .image {
  padding-top: 20px;
}

.promo-slider-block .inner-slide .image img {
  max-width: 100%;
  padding-top: 30px;
  padding-bottom: 20px;
}

.promo-slider-block .inner-slide .description {
  font-weight: bold;
}

.promo-slider-block .inner-slide-1 {
  background: #00f0ff;
}

.promo-slider-block .inner-slide-1 .description span {
  color: #FFF;
}

.promo-slider-block .inner-slide-2 {
  background: #fffa83;
}

.promo-slider-block .inner-slide-2 .description span {
  color: #2CDAE5;
}

.promo-slider-block .inner-slide-3 {
  background: #5b5b5b;
}

.promo-slider-block .inner-slide-3 .description {
  color: #FFF;
}

.promo-slider-block .inner-slide-3 .description span {
  color: #2CDAE5;
}

.promo-slider-block .inner-slide-4 {
  background: #ededed;
}

.promo-slider-block .inner-slide-4 .description span {
  color: #2CDAE5;
}

.section-block-3 .top-block {
  padding-top: 50px;
  max-width: 470px;
  margin: 0 auto;
}

.section-block-3 .top-block .text {
  background: #EBEBEB;
  padding: 30px 30px 50px 30px;
  font-size: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.section-block-3 .top-block .buttons {
  padding-top: 20px;
}

.section-block-3 .top-block .buttons .btn-form {
  max-width: 250px;
  padding: 15px 20px !important;
}

.section-block-3 .top-block img {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.section-block-4 .top-block {
  padding-top: 50px;
  max-width: 1320px;
  margin: 0 auto;
  text-align: center;
}

.section-block-4 .top-block .title {
  font-size: 48px;
  font-weight: bold;
}

.section-block-4 .top-block .image {
  position: relative;
  padding-top: 30px;
  display: flex;
  justify-content: space-evenly;
}

.section-block-4 .top-block .image img {
  padding: 0 5px;
}

.section-block-4 .map-block {
  padding-top: 30px;
  text-align: center;
}

.section-block-4 .map-block img {
  max-width: 100%;
  display: inline-block;
}

.partner-swiper-btn {
  position: absolute;
  z-index: 100;
  top: 60px;
  width: 55px;
  height: 55px;
  border: unset;
  border-radius: 27px;
  background-color: rgba(242, 242, 242, 0.35);
}

.swiper-btn-left {
  left: 0;
}

.swiper-btn-right {
  right: 0;
}

.section-review {
  padding-top: 50px;
}

.section-review .inner-slide {
  padding-bottom: 45px;
  padding-top: 30px;
}

.section-review .title {
  color: #343434;
  font-size: 48px;
  font-weight: bold;
}

.section-review .name {
  font-weight: bold;
  padding-bottom: 20px;
  font-size: 18px;
}

.section-review .text {
  font-style: italic;
  font-size: 18px;
}

.section-faq {
  padding-top: 50px;
}

.section-faq .title {
  color: #343434;
  font-size: 48px;
  font-weight: bold;
  padding-bottom: 30px;
}

.section-faq .faq-item {
  padding-bottom: 20px;
}

.section-faq .faq-bottom {
  padding-top: 30px;
}

.section-faq .faq-item .caption,
.section-faq .faq-bottom .caption {
  color: #000 !important;
  text-decoration: none !important;
  font-size: 18px;
  font-weight: bold;
  display: block;
}

.section-faq .faq-item .caption span {
  float: right;
}

.section-faq .faq-item .caption .expand-show {
  color: #A1A1A1 !important;
}

.section-faq .faq-item.expand .expand-show {
  display: none;
}

.section-faq .faq-item:not(.expand) .expand-hide {
  display: none;
}

.section-faq .faq-item .text,
.section-faq .faq-bottom .text {
  color: #898989;
  font-size: 16px;
  padding-top: 10px;
  line-height: 28px;
}

.section-faq .faq-item.expand .text {
  display: none;
}

.section-bottom {
  padding: 10px 20px 0 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #2B2B2B;
  max-width: 470px;
  margin: 50px auto 0;
}

.section-bottom .title {
  color: #FFF;
  font-size: 22px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 10px;
}

.section-bottom .h-subtext {
  padding-bottom: 15px;
}

.section-bottom .h-subtext a {
  color: #848484 !important;
  text-decoration: underline !important;
}

.section-bottom .btn-form {
  background: #00F0FF !important;
  color: #1C1C1C !important;
}

.section-bottom .contacts {
  padding-top: 50px;
  padding-bottom: 30px;
}

.section-bottom .contacts .info {
  line-height: 30px;
  padding-bottom: 10px;
}

.section-bottom .contacts .info a {
  color: #FFF !important;
  text-decoration: none !important;
}

.section-bottom .contacts .social {
  float: right;
}

.section-bottom .contacts .social .fa {
  width: 20px;
  color: #FFF;
  font-size: 28px;
  line-height: 20px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 15px;
}

.section-bottom .contacts .social .fa-whatsapp {
  color: #00D916;
}

.section-bottom .contacts .social .fa-instagram {
  color: #f65db1;
}

.section-bottom .contacts .social .icon-round {
  border-radius: 50%;
  background: #039BE5;
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 20px;
  text-align: center;
}

.section-bottom .contacts .social .fa-facebook {
  color: #FFF;
  font-size: 24px;
}

.section-bottom .contacts .info .fa {
  width: 20px;
  color: #2CDAE5;
}

</style>